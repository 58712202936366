import React, { Component } from "react";
import "./font-awesome.css";
import "./App.css";
import Header from "./Header/Header";
import Companies from "./Companies/Companies";
import Interests from "./Interests/Interests";
import Projects from "./Projects/Projects";
import Schools from "./Schools/Schools";
import bg from "./background.jpg";

class App extends Component {
  render() {
    return (
      <div
        className="App"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundColor: "rgb(254, 252, 252)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundPosition: "bottom"
        }}
      >
        <Header />
        <main className="App-main">
          <Companies />
          <Projects />
          <Schools />
          <Interests />
        </main>
        <footer className="App-footer"></footer>
      </div>
    );
  }
}

export default App;
