import React from "react";
import "./Header.css";

const Header = () => (
  <header className="Header">
    <div className="Header-container">
      <h1 className="Header-logo">Adam Thomann</h1>
      <h1 id="sm-logo">A.D.T.</h1>
      <nav className="Header-nav">
        <ul>
          <li>
            <a
              className="linkedin"
              href="http://linkedin.com/in/adamthomann/"
              target="_blank"
            >
              <i className="fa fa-linkedin-square fa-2x" />
            </a>
          </li>
          <li>
            <a
              className="github"
              href="http://github.com/athomann"
              target="_blank"
            >
              <i className="fa fa-github-square fa-2x" />
            </a>
          </li>
          <li>
            <a
              className="twitter"
              href="http://twitter.com/adamthomann"
              target="_blank"
            >
              <i className="fa fa-twitter-square fa-2x" />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </header>
);

export default Header;
