import React, { Component } from "react";
import "./Schools.css";

class Schools extends Component {
  render() {
    const schools = [
      {
        name: "University at Buffalo",
        location: "Buffalo, NY",
        timeline: "September 2008 - May 2010",
        major:
          "Bachelor Degree; Political Science: Concentration in International Relations"
      },
      {
        name: "Niagara University",
        location: "Niagara University, NY",
        timeline: "September 2006 - May 2008",
        major: "Double Major; Political Science & International Studies;",
        activities: {
          clubs: "Member of Model UN Club, Member of Philosophy Club",
          awards: "Recipient of Grant Scholarship from Niagara University",
          events:
            "Attended Eurosim 2008: Conflict Resolution in Kosovo; European Academy, Otzenhausen, Germany<br>Attended Young Global Leaders’ Summit Securing the Future:<br> Oil Dependence and What We Can Do About It;<br>Cornell University, Ithaca, NY<br>Attended EuroSim 2007: Environment and Energy Policy;<br>Canisius College, Buffalo, NY<br>"
        }
      }
    ];

    return (
      <section className="App-wrap">
        <h1 className="App-section-heading">Education</h1>
        {schools.map(school => (
          <div className="App-container">
            <h2 className="App-heading">{school.name}</h2>
            <p className="App-timeline">{school.timeline}</p>
            <p className="App-description">{school.major}</p>
          </div>
        ))}
      </section>
    );
  }
}

export default Schools;
