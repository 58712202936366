import React, { Component } from "react";
import "./Projects.css";

const projects = [
  {
    name: "Bike Lane Uprising Maps",
    timeline: "2020",
    description: "Built BLU Maps using React, Mapbox, Deck.Gl & TypeScript",
    url: "https://whitehouseuprising.github.io/maps2"
  },
  {
    name: "Troops Dashboard",
    timeline: "October 2016 - present",
    description:
      "Architecting Troops dashboard using React, Redux, webpack, babel, and flowtypes.",
    url: "http://app.troops.ai"
  },
  {
    name: "StockTwits.com",
    timeline: "September 2015 - 2016",
    description:
      "Architecting a StockTwits rebuild using React, webpack, babel.",
    url: "http://www.stocktwits.com"
  },
  {
    name: "Goodnes",
    timeline: "2015",
    description: "Led development on Goodnes.  Built with Angular, Require.js",
    url: "http://goodnes.com"
  },
  {
    name: "Nesquik.com",
    timeline: "2014",
    description:
      "On the development team for the rebranded Nesquik.com website.",
    url: "http://nesquik.com"
  },
  {
    name: "Deep Snow",
    timeline: "2014",
    description: "Helped architect an inteneral game project using Require.js",
    url: "http://deepsnow.deepfocus.net/play"
  },
  {
    name: "Beyond Petfood",
    timeline: "2014",
    description:
      "Led a complete refactor the website.  Converted site to using module design with Require.js",
    url: "https://www.beyondpetfood.com/"
  },
  {
    name: "Deep Focus",
    timeline: "2014",
    description: "Worked on many developmental changes site wide.",
    url: "https://deepfocus.net"
  },
  {
    name: "Adobe Creative Suite Scripts",
    timeline: "2013",
    description:
      "Developed scripts with Javascript for Adobe Creative Suite that increase productivity and help with workflow."
  },
  {
    name: "CVLT Production Website",
    timeline: "2013",
    url: "http://cvltproduction.com",
    description:
      "Responsible for developing the CVLT Production website using HTML5, CSS3, and Javascript."
  },
  {
    name: "CVLT Projects",
    timeline: "2013 - 2014",
    description:
      "Responsible for the front & back-end development for the CVLT’s internal project manager using HTML5, CSS3, & Javascript."
  },
  {
    name: "ADF Launchpad",
    timeline: "2012",
    url: "http://adftheloop.com",
    description:
      "Responsible for the front-end design & development of the Aramis & Designer Fragrances launchpad with HTML5, CSS3, Javascript and Ruby on Rails."
  },
  {
    name: "Urban Studio Website",
    timeline: "2012",
    url: "http://urbanstudionyc.com",
    description:
      "Responsible for developing the Urban Studio website using HTML5, CSS3, & jQuery."
  },
  {
    name: "True Vodka",
    timeline: "2011",
    url: "http://truevodka.com",
    description: "Developed the True Vodka website using HTML5, CSS3, & jQuery."
  },
  {
    name: "The Loop",
    timeline: "2011",
    description:
      "Revamped the Front End development & design for Urban Asset Management system.  Upgraded the Front-End to HTML5 & CSS3."
  }
];

const Projects = () => (
  <section className="App-wrap">
    <h1 className="App-section-heading">Projects</h1>
    {projects.map(project => (
      <div className="App-container">
        <a className="App-heading" href={project.url} target="_blank">
          {project.name}
        </a>
        <p className="App-timeline">{project.timeline}</p>
        <p className="App-description">{project.description}</p>
      </div>
    ))}
  </section>
);

export default Projects;
