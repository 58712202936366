import React, { Component } from "react";
import "./Companies.css";

const companies = [
  {
    name: "Bike Lane Uprising",
    url: "https://whitehouseuprising.github.io/maps2",
    timeline: "August 2019 - Present",
    title: "Volunteer Engineer",
    description: "Volunteer creating maps for Bike Lane Uprising"
  },

  {
    name: "CompStak",
    url: "https://compstak.com",
    timeline: "February 2019 - Present",
    title: "Lead Front-End Engineer",
    description:
      "Lead Front-end development on numerous internal & external projects at CompStak."
  },
  {
    name: "Troops, Inc",
    url: "https://troops.ai",
    timeline: "October 2016 - February 2019",
    title: "Senior Software Engineer",
    description:
      "Led the Front End development on the Troops dashboard. Technologies used include React, Redux, RxJS, Flow Type, Immer"
  },
  {
    name: "StockTwits",
    url: "https://www.stocktwits.com",
    timeline: " September 2015 - September 2016",
    title: "Senior Front End Engineer",
    description:
      "Led the Front End development on the rebuilding of StockTwits.com. Technologies used include React, Redux, Immutable.js, Node, RxJS"
  },
  {
    name: "Deep Focus",
    url: "https://deepfocus.net",
    timeline: "July 2014 - September 2015",
    title: "Front End Developer",
    description:
      "Responsible for developing web apps & websites for clients. Technical skills include HTML5, CSS3, Javascript, and frameworks/libraries as Angular.js, jQuery, & Bootstrap."
  },
  {
    name: "Urban Studio / CVLT Production",
    url: "http://urbanstudionyc.com",
    timeline: "February 2011 – July 2014",
    title: "Front & Back End Developer",
    description:
      "Responsible for designing & developing web apps & websites for clients & internal use. Technical skills include HTML5, CSS3, Javascript, and Ruby along with frameworks/libraries as Ruby on Rails, Angular.js, jQuery, & Bootstrap."
  },
  {
    name: "Ageless Heirlooms",
    url: "http://agelessheirlooms.com",
    timeline: "April 2010 - February 2011",
    title: "Social Media Marketer",
    description:
      "Managed social media, wrote blog posts, & produced photoshoots that increased brand recognition & sales."
  },
  {
    name: "Freelance Web Development",
    timeline: "March 2010 - 2013",
    description: "Developed personal & business websites for clients."
  }
];

const Companies = () => (
  <section className="App-wrap">
    <h1 className="App-section-heading">Experience</h1>
    {companies.map(company => (
      <div className="App-container">
        <h2 className="App-heading">{company.title}</h2>
        <a className="App-description" href={company.url} target="_blank">
          {company.name}
        </a>
        <p className="App-timeline">{company.timeline}</p>
        <p className="App-description">{company.description}</p>
      </div>
    ))}
  </section>
);

export default Companies;
