import React, { Component } from "react";
import "./Interests.css";

const interests = [
  {
    name: "Technologies",
    description:
      "Typescript, React, React Hooks, Mapbox, Deck.gl, Nebula.gl, RxJS, styled-components, Immer, Immutable.js, Functional Programming, CSS3/HTML5, PostCSS, Less, SASS, Ruby, Ruby on Rails, Adobe Creative Suite, Git."
  },
  {
    name: "Analytical",
    description:
      "Environmental & Energy Policy, Sustainability, Political Science, International Relations & Conflict Resolution, Game Theory, Geographic Information Systems"
  },
  {
    name: "Creative",
    description:
      "Design. Music Composition & Improvisation: Guitar, Piano, Upright Bass Film Production: Acting, Cinematography, Film Editing Photography"
  }
];

const Interests = () => (
  <section className="App-wrap">
    <h1 className="App-section-heading">Skills & Interests</h1>
    {interests.map(interest => (
      <div className="App-container">
        <h2 className="App-heading">{interest.name}</h2>
        <p className="App-description">{interest.description}</p>
      </div>
    ))}
  </section>
);

export default Interests;
